@font-face {
  font-family: "Lato";
  src: url("../assets/Lato-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../assets/Lato-Light.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url("../assets/Lato-Bold.ttf") format("ttf");
  font-weight: 500;
}

* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

html {
    color: #403b3b;
}

html, body, #root {
  height: 100%;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}
